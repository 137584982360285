import logo from './logo.svg';
import './App.css';
import UploadForm from "./pages/UploadForm"

function App() {
  return (
    <div>

    <UploadForm />
    </div>
  );
}

export default App;
 