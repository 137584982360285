import { TextField } from '@material-ui/core';
import React from 'react';

export default function Input(props) {
     
    const {name, label, value, onChange, error, helperText, disabled} =props;

    return (  
        <TextField 
            variant="outlined"
            disabled={disabled && "true"}
            id="outlined-helperText" 
            fullWidth 
            label={label}
            name={name} 
            value={value} 
            onChange={onChange} 
            error={error}
            helperText={helperText}
            
        />
    )
}
