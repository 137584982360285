import React,{useState} from 'react';



export function useForm(initialFValues) {

    const [values, setValues] = useState(initialFValues);

    const handleVideoRemarks = (event) => {
        const {name, value} = event.target;
        
        let temp = value.remarksChecked;
        //const target = event.target;
        //var value = target.value;
        if (value.checked) {
            temp.push(value);
        } else {
            temp.splice(temp.indexOf(value), 1);
        }

        setValues(temp);
        console.log(temp);
    }

    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
     }

    return {
        values, 
        setValues,
        handleVideoRemarks,
        handleInputChange
    }



}
 

export function Form (props) {
    return (  
        <form>
            {props.children}
        </form>
    );
}

