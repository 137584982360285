import Input from "./Input";
//import RadioGroup from "./RadioGroup";
//import Select from "./Select";
import Checkbox from "./Checkbox";
//import DatePicker from "./DatePicker";
//import Button from "./Button";
//import ActionButton from "./ActionButton";

const Controls = {
    Input,
    Checkbox,
   //  RadioGroup,
   //  Select,
   // DatePicker,
   // Button,
   // ActionButton
}

export default Controls;