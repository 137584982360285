import { useEffect, useState } from "react"


export function useSubmitter (base) {
    const [submitters, setSubmitters] = useState([]);

    useEffect(() => {
        base('Personen').select({
            view: 'Grid view'
        }).firstPage(function (err, records) {
            if (err) { console.error(err); return; }
            records.forEach(function (record) {
                //console.log('Retrieved', record.get('Naam'));
            });
        // setSubmitters(records);
            //console.log(records)
            setSubmitters(records)

        }); 
    }, [])

    return {submitters};
}


export function useStandardRemark (base) {
    const [videoRemarks, setVideoRemarks] = useState([]);

    useEffect(()=>{
        base('StandardRemarks').select({
            view: 'Grid view'
        }).firstPage(function (err, records) {
            if (err) { console.error(err); return; }
            records.forEach(function (record) {
               // console.log('Retrieved', record.get('Name'));
            });
            //console.log(records)
            setVideoRemarks(records);
        });
    },[])
    return {videoRemarks};
}

export function useThumbnail (base) {
    
    const [thumbnail, setThumbnail] = useState([]);

    useEffect(()=>{
        base('Thumbnails').select({
            view: 'Grid view'
        }).firstPage(function (err, records) {
            if (err) { console.error(err); return; }
            let temp = [];
            records.forEach(function (record) {
                temp.push(record.get('Thumbnail')[0].url);
                //console.log('Retrieved', record.get('Thumbnail')[0].url);
                // console.log('Retrieved_T', temp);

            });
            setThumbnail(temp);
        });
    },[])
    return {thumbnail};
}
    
export function useFeedback (base) {
    
    const [feedback, setFeedback] = useState([]);
    
    useEffect(()=>{
        base('ChecklistVimeo').select({
            view: 'Grid view'
        }).firstPage(function (err, records) {
            if (err) { console.error(err); return; }
            setFeedback(records);
            console.log('feed', feedback);
            records.forEach(function (record) {
                //console.log('Retrieved', record.get('Name'));
            });
        });
    },[])
    return {feedback};
}
    