import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
//import { makeStyles } from '@material-ui/styles';
import * as tus from 'tus-js-client'
import Typography from '@material-ui/core/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@mui/material/InputLabel';
import { CssBaseline, Paper, Link, FormLabel, FormControl } from '@material-ui/core';
import { useState } from "react";
import Airtable from "airtable";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios';
import Controls from "../components/controls/Controls";
import reactDom from 'react-dom';

import Input from "../components/controls/Input"; 
import {useForm, Form} from  "../components/useForm"; 
import {useSubmitter, useStandardRemark, useThumbnail, useFeedback} from "../services/submittersService.js";

const override = css`
  display: block;
  margin: 0 auto;
  margin-top: 35%;
  display: flex;
  justify-content: center
  border-color: red;
`;

const base = new Airtable({ apiKey: 'keykZF9N4p4pyNK2b' }).base('apphMT5j6gtUnMY1p');

const accessToken = '6b564243c55c067d5d90d52fec69feff';

const headerPost = {
    Authorization: `bearer ${accessToken}`,
    Accept: 'application/vnd.vimeo.*+json;version=3.4',
    'Content-Type': 'application/json'
};
/*
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 900,
        height: 900,
        overflow: 'auto',
        marginTop: "1%",
        marginLeft: "30%",
        backgroundColor: "##74992e"
    },
    // #091217
}));
*/

const initialFValues ={
    feedback:'',
    thumbnail: '',
    submitter: '',
    typeRemarks: false,
    isPermanent: false,
    remarksChecked: []
}



export default function FormAll() {

    const [proccess, setProccess] = React.useState(false);
    const [previousRemarks, setPreviousRemarks] = React.useState([]);
    
    const [videoNumber, setVideoNumber] = React.useState(null);
    const [deliverableID, setDeliverableID] = React.useState("");
    const [submitter, setSubmitter] = React.useState("");
    const [typeRemarks, setTypeRemarks] = React.useState(false);
    const [clientName, setClientName] = React.useState("");
    const [pNamee, setPNamee] = React.useState("");
    const [selectedThumbnail, setSelectedThumbnail] = React.useState("");
    const [projectId, setProjectId] = React.useState("")
    const [opmerking, setOpmerking] = React.useState("");
    const [versies, setVersies] = React.useState([]);
    const [deliverables, setDeliverables] = React.useState([]);
    const [deliverableChecked, setDeliverableChecked] = React.useState("");
    const [newDeliverableName, setNewDeliverableName] = React.useState("");
    const [mailPL, setMailPL] = React.useState(true);

    
    const [versieNumber, setVersieNumber] = React.useState(1);

    const [name, setName] = React.useState("");

    // load standard values form airtablebase
    const submitters = useSubmitter(base).submitters;
    const videoRemarks = useStandardRemark(base).videoRemarks;
    const thumbnail = useThumbnail(base).thumbnail;
    const feedback = useFeedback(base).feedback;



    const [perc, setPerc] = React.useState(0);
    const [videoUrl, setVideoUrl] = useState('');

    const [videoUrl2, setVideoUrl2] = useState('');
    const [videoUrlManage, setVideoUrlManage] = useState('');

    const [video, setVideo] = useState("");
    const [feedbackProcessed, setFeedbackProcessed] = useState([]);
    const [remarksChecked, setRemarksChecked] = useState([]);
    
    const [errors, setErrors] = useState({});

    const [projectNo, setProjectNo] = useState(0);
    const [projectNaam, setProjectNaam] = useState("");



    // initiate inital values
    const {
        values,
        setValues,
        handleInputChange,
        handleVideoRemarks
    } = useForm(initialFValues);



    function handleVideo(e) {
        let eventObject = e;
        const fileName = eventObject.target.files[0]?.name;
        const fileSize = eventObject.target.files[0]?.size.toString();
        setVideo(eventObject.target.files[0]);
    }

    function handleNewDeliverable(event) {
    
        const projectNumber = new URLSearchParams(window.location.search).get('prefill_Projecten');
        
        console.log('newDeliverableName', newDeliverableName);

        let temp={};

        console.log(deliverableID);

        temp.projectNumber=projectId ? "" : "Projectnummer is een verplicht veld voor het aanmaken van een deliverable.\n";
        temp.newDeliverableName=newDeliverableName ? "" : "Je hebt geen deliverable naam opgegeven.\n";

        //define all errors from temp file
        if (!Object.values(temp).every(x => x=="")){
            window.alert(temp.projectNumber + temp.newDeliverableName);
        } else {
            
            const body = {

                "delNaam":""+ newDeliverableName,
                "Project": { "id": "" + projectId, "no": projectNo }

            }
            const headers = {
                'Content-Type': 'application/json',
                // "Access-Control-Allow-Origin": "*",
            }

            
            axios.post("https://hook.eu1.make.com/vipm5jmvatmnvitg88wii9gekvaclwrc", body, { headers: headers }).then(
                (r) => console.log(r)).catch((err) => console.log(err));

            window.location.reload(false);
            console.log('projectNummer:::'+projectNumber);             
            //getProject (projectNumber);    

        }
        setErrors({
            ...temp
        })
        


    }
    
    // this function will be called when video changes.
    React.useEffect(() => {
    }, [video]);

    // load prefill variables 
    React.useEffect(() => {
        const projectNumber = new URLSearchParams(window.location.search).get('prefill_Projecten');
        console.log("Useffect function called");
        console.log("prefill project gevonden"+projectNumber);
     
        if (projectNumber) {
            setProjectNo(projectNumber);
            getProject(projectNumber);
        } else {
            setProjectNo("");
        }
      
    }, []);
    
    // Haal projectinformatie op, zoals versie, deliverables en informatie over project
    // 9/6/22: gewijzigd van useCallBack naar gewone functie en met async en await
    async function getProject (projectNumber) {
        await base('Projecten').select({
            // view: 'Grid view',
            filterByFormula: "{Projectnummer} = '" + projectNumber + "'"
        }).firstPage(function (err, records) {
            if (err) { 
                console.error(err); return;
            }
            console.log("projecten midden")
            records.forEach(function (record) {
                let projectN ="";
                let klantN="";
                projectN =record.get('Projectnaam');
                klantN=record.get('KlantnaamString');

                setPNamee(projectN);
                setClientName(klantN);
                setProjectId(record.get('recordID'));
                setProjectNaam(""+projectNumber+". "+klantN+" - " +projectN);
            });
        });

        if(!pNamee) {
            setProjectNaam("Geen geldig projectnummer")
            console.log("geen project gevonden")
        }

        await base('RemarksEdit').select({
            // view: 'Grid view'
            filterByFormula: "{Project} ='" + projectNumber + "'"
        }).firstPage(function (err, records) {
            if (err) { console.error(err); return; }
            setPreviousRemarks(records);
        });

        console.log("AND({Projectnummer} = '" + projectNumber + "', {deliverableID} = '"+ {deliverableChecked}+ "')");

        await base('Versie').select({
            view: 'Grid view',
            filterByFormula: "AND({Projectnummer} = '" + projectNumber + "', {deliverableID} = '{deliverableChecked}')"
         
        }).firstPage(function (err, records) {
            if (err) { console.error(err); return; }
            setVersies(records);

        });

        console.log("delIDs");
        console.log("{deliverableID} = '" + deliverableID + "'");

        await base('Deliverables').select({
            view: '(FFAPP)_AllDeliverables',
            filterByFormula: "{Projectnummer} = '" + projectNumber + "'"
        }).firstPage(function (err, records) {
            if (err) { 
                console.error(err); 
                console.log("GEEN DELIVERABLES!")
                //setDeliverables("");
                return; 
            }
            setDeliverables(records);
            records.forEach(function (record) {   
                console.log(record.fields);

            });
            console.log("deliverables");
            console.log(deliverables.length);
        });

        const fetchData = async () => {
            function sleep(ms) {
              return new Promise(resolve => setTimeout(resolve, ms));
            }
        
            // waits for 1000ms
            await sleep(3000);
            return 'Hello World';
          };

        const result = fetchData();
        console.log(result);

        if (deliverables.length==0){
            console.log("GEEN DELIVERABLES!33");
        }
        console.log("GEEN DELIVERABLES!");
        /*
        if (!deliverableID){
            setDeliverableChecked('Main');
            setDeliverableID("Main"); 
            window.alert(deliverableID);
            console.log("no del ID");
            console.log("delchecked :"+ deliverableChecked);
            console.log("deliverableID :"+ deliverableID);
            console.log(deliverableID);
        } else {
            //console.log("testfunctie sub"+ deliverables.find(o => o.fields.Name === submit).fields.Name);

            function isField(subarray) {
                return subarray.fields.deliverableID === deliverableID; 
            }
         
            console.log(deliverableChecked);            
        } */
    };

    
    const validate =()=>{
        let temp={};

        console.log(deliverableID);

        temp.projectNumber=projectId ? "" : "Projectnummer is een verplicht veld\n";
        temp.deliverableID=deliverableID.length ? 0 : "Je hebt geen deliverable geselecteerd. Is er geen deliverable gekoppeld aan het project? Maak deze dan eerst aan via airtable.\n";
        temp.video=video ? "" : "Je hebt geen video geupload\n";
        temp.opmerking=opmerking ? "" : "Je hebt geen opmerking voor editor toegevoegd\n";
        temp.submitter=submitter ? "" : "Je hebt je eigen naam niet opgegeven.\n";
  

        //define all errors from temp file
        if (!Object.values(temp).every(x => x=="")){
            window.alert(temp.projectNumber+temp.video+temp.opmerking+temp.submitter+temp.deliverableID);
        }
        setErrors({
            ...temp
        })
        
        // als alles in temp gelijk is aan "" dan return true, anders false 
        return Object.values(temp).every(x => x=="");
    }



    // als er op button GET LINK geklikt wordt start deze functie.
    //--------------------------------------------------------------------------------------------------------------------
    const handleChange = async () => {
        if (!validate()){
            //window.alert('Niet alle verplichte velden ingevuld correct (projectnr/video');
            console.log(errors);
        } else {

            setProccess(true);

            const getUrl = `https://api.vimeo.com/videos/${videoNumber}/versions`;


            // first version
            console.log("NEW VIDEO");
            function isFieldDeliverable(subarray) {
                return subarray.fields.deliverableID  === deliverableID; 
            }
            console.log('deliverables records hieronder:')
            console.log(deliverables)
            console.log('deliverableid'+deliverableID)
            function isField(subarray) {
                return subarray.fields.Naam === submitter; 
            }
            let UploadName = ""+ projectNaam+" - " +deliverables.find(isFieldDeliverable).fields.NaamDeliverable+" ("+submitters.find(isField).fields.Initialen+") - v"+versieNumber;
            console.log(projectNaam);
            console.log('UploadName');
    
            console.log(UploadName);
    
            
            
            // return 0;
            const response = await axios({
                method: 'post',
                url: `https://api.vimeo.com/me/videos`,
                headers: headerPost,
                data: {
                    upload: {
                        approach: 'tus',
                        size: video.size,
                        name: video.name,
                        filetype: video.type,
                    },
                    name: UploadName,
                    "file_name": "" + UploadName,
                    "review_page.active": true,
                    review_page:{
                        active: true,
                    }
                    
                }
            });
            console.log('response')
            console.log(response)
            setVideoUrl(response.data.review_page.link);
            setVideoUrl2(response.data.link)
            setVideoUrlManage("https://vimeo.com/manage/"+response.data.uri.split("/")[2]+"/collaboration")
            console.log(response.data.review_page.link);
            const uri = response.data.metadata.connections.pictures.uri;
            
            // get thumbnail url
            const response2 = await axios({
                method: 'post',
                headers: headerPost,
                url: "https://api.vimeo.com" + uri.toString(),
            });

            console.log('response2')
            console.log(response2)
            // upload thumbnail
            const h = {
                "Content-Type": "image/jpeg",
                "Accept": "application/vnd.vimeo.*+json;version=3.4"
            }
            const response3 = await axios({
                method: 'put',
                headers: h,
                url: "" + response2.data.link,
                data: selectedThumbnail,
            });

            console.log('response3')
            console.log(response3)

            // set default picture
            const body = {
                "active": true,
                "default_picture": true,
            }
            const r4u = "https://api.vimeo.com" + response?.data?.uri + "/pictures/" + (response3?.data?.Path?.split("/")[2])
            
            const response4 = await axios({
                method: 'patch',
                headers: headerPost,
                url: r4u,
                data: body,
            });
            //console.log(response4);
            console.log('response4')
            console.log(response4)

            // Change reviewlink to active
            const body2 = {
                review_page:{
                    active: true,
                },
                privacy:{
                    download: true,
                },
            }
            const url2 = "https://api.vimeo.com" + response?.data?.uri 
            console.log('url2: '+url2)
            const response5 = await axios({
                method: 'patch',
                headers: headerPost,
                url: url2,
                data: body2,
            });
            //console.log(response4);
            console.log('response5')
            console.log(response5)

            const upload = await new tus.Upload(video, {
                endPoint: 'https://api.vimeo.com/me/videos',
                body: { 
                    name: video.name,
                    review_page:{
                        active: true,
                    }         
                },
                uploadUrl: response.data.upload.upload_link,
                metadata: {
                    name: video.name,
                    filetype: video.type,
  
                },
                headers: {},
                onError: function (error) {
                    console.log('Failed because: ' + error);
                    setPerc(100);
                    setVideoUrl(error.message.toString());
                },
                onProgress: function (bytesUploaded, bytesTotal) {
                    let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(0);
                    setPerc(percentage);
                },
                onSuccess: function () {
                    const vId = response.data.uri.split("/")[2];

                    function isField(subarray) {
                        return subarray.fields.Naam === submitter; 
                    }

                // create versie in Airtable using MAKE (webhook)
                    const body = {
                        "Vimeolink": "" + response.data.review_page.link,
                        "Vimeolink2": "" + response.data.review_page.link,
                        "Editor": {"id": "" + submitters.find(isField).fields.recordID, "naam": "" + submitter},
                        "Project": { "id": "" + projectId, "no": projectNo },
                        "FeedbackProcessed": feedbackProcessed,
                        "ReadRemarks": "" + typeRemarks,
                        "StandardRemarks": remarksChecked,
                        "VersionNr": "" + versieNumber,
                        "Opmerkingen": "" + opmerking,
                        "videoID": vId,
                        "VideoNaam": "" + UploadName,
                        "delID":""+ deliverableID,
                        "MailPL": mailPL,
                    }
                    const headers = {
                        'Content-Type': 'application/json',
                        // "Access-Control-Allow-Origin": "*",
                    }

                    
                    axios.post("https://hook.eu1.make.com/xbtud96p9i7b3gzuqol41c32g9gj1hqs", body, { headers: headers }).then(
                        (r) => console.log(r)).catch((err) => console.log(err));
                    
                }
            });
            console.log('upload');
            console.log(upload);
            upload.start();
            
        }

      
    };

    const handleFeedback = (event) => {
        let temp = feedbackProcessed;
        const target = event.target;
        var value = target.value;
        if (target.checked) {
            temp.push(value);
        } else {
            temp.splice(temp.indexOf(value), 1);
        }
        setFeedbackProcessed(temp);
        setOpmerking(temp.join('\r\n'));
        console.log(temp.join);
        console.log(opmerking);
    }

    const handleMailPL = (event) => {

        setMailPL(event.target.checked);
        console.log(mailPL);
    }

    const handleDeliverable = (event) => {
        var length =1;
        //const selectedIndex = event.target.options.selectedIndex;
        let tempDeliverableID; 
        //tempDeliverableID= event.target.options[selectedIndex].getAttribute('data-key');
        

        setDeliverableID(event.target.value);
        setDeliverableChecked(event.target.value);
        
        console.log("del checked handle"); 
        console.log("{deliverableID} = '" + event.target.value + "'"); 
        console.log("delchecked: "+deliverableChecked);
        console.log("projectNo: "+projectNo);
        
        base('Versie').select({
            view: 'Grid view',
            filterByFormula: "{deliverableID} = '" + event.target.value + "'"
         
        }).firstPage(function (err, records) {
            if (err) { console.error(err); return; }
            setVersies(records);
            console.log("videonmber before"+ videoNumber);   
            setVideoNumber(null); 
            console.log("videonmber after"+ videoNumber);   
             
            records.forEach(function (record) {          
                setVideoNumber(record.get('videoID'));
                console.log(record);
                console.log("videoID "+record.get('videoID'));
                console.log("videonmber"+ videoNumber);   
                
                // check number of versies to set version number.
                ++length;
                console.log("length: "+length);
            });
            setVersieNumber(length);
               
        });
        console.log("first link: ")
        
        
        console.log(versies)     
        function isField(subarray) {
            return subarray.fields.deliverableID  === deliverableID; 
        }
        
        console.log("deliverablename "+ "id:"+deliverables.find(isField).fields.Name);
        //console.log(deliverableID);
        //console.log(deliverableChecked);
                
    }



    const handleProjectNumber = (event) => {
        console.log(event.target.value);
        setProjectNo(event.target.value);
        // if length=4 look up projectnumber
        if (event.target.value.length>0){
            if (event.target.value.length===4){
                console.log("4 karakters");
                getProject(event.target.value);
            }
        }

    }

    const handleVersieNumber = (event) => {
        console.log("versienumberEv: "+event.target.value);
        setVersieNumber(event.target.value);
        console.log("versienumber: "+versieNumber);
    }


    {

        return (
            <div style={{ padding: 16, margin: 'auto', maxWidth: 700 }}>
                <CssBaseline />
                <Typography variant="h4" align="center" component="h1" gutterBottom>
                    <strong >UPLOADEN VIMEO (GEEN VERSIE)</strong>
                </Typography>

                {!proccess && (
                    <Form>
                        <Paper style={{padding:16}}>
                            {/* Projectnaam*/} 
                            <Input
                                label="Projectnummer"
                                value={projectNo}
                                onChange={handleProjectNumber}
                                error={errors.projectNumber && "true"}
                                helperText={errors.projectNumber && errors.projectNumber}

                            />
                            
                            <br></br>
                            <br></br>

                            <Input 
                                disabled="true"
                                id="standard-basic"
                                label={projectNaam}  
                                onChange={(e) => setName(e.target.value)} 
                            />
                            <br></br>
                            <br></br>

                            <Typography style={{ fontWeight: "bold", fontSize: 20 }} align='left'>
                                Previous Remarks
                            </Typography>
                            {previousRemarks?.map(record => {
                                return (
                                    <div style={{ marginBottom: "5px", justifyContent: "center" }}>
                                        <TextField disabled id="standard-basic" label={record.get('Note')} fullWidth />

                                    </div>
                                );
                            })}
                            <br></br>
                            <br></br>
                            
                            <Typography style={{ fontWeight: "bold", fontSize: 20 }} align='left'>
                                Kies versie van deliverable om te uploaden:
                            </Typography>

                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                //defaultValue={deliverableChecked}
                                name="radio-buttons-group"
                            >
                                {deliverables?.map(record => {
                                    return(
                                        <FormControlLabel value={record.get('deliverableID')} control={<Radio />} label={record.get('PrNr_Naam_Del')} data-key={record.get('deliverableID')} onChange={handleDeliverable}>
                                        </FormControlLabel>

                                        
                                    ); 
                                })}

                            </RadioGroup>


                            <br></br>                                    


                            <Link href={`https://airtable.com/shrfVX7SKMNK4KxxR?prefill_Project=${projectNo}`} variant="body2" target="_blank">
                                Create new deliverable
                            </Link>
           

                            <br></br>
                            {versies.length > 0 && (   
                            <div>
                                <Typography style={{ fontWeight: "bold", fontSize: 20 }} align='left'>
                                    Voorgaande versies:
                                </Typography>


                            </div>
                            )} 

                            {versies?.map(record => {
                                return (
                                    <div>
   
                                            <List dense>
                                                <ListItem>
                                                <ListItemText 
                                                    primary=
                                                    {<Link href={record.get('Vimeolink')} target="_blank">
                                                        Versie {record.get('VersionNr')} - Vimeo ID: {record.get('videoID')} - Remark: {record.get('Opmerkingen')} 
                                                    </Link>}
                                                />                                 
                                                </ListItem>     
                                            </List>    

                                    </div>
                                );
                            })} 


                            <br></br>
                            <Typography style={{ fontWeight: "bold", fontSize: 20 }} align='left'>
                                Geef versienummer.
                            </Typography>
                            <Typography style={{ fontWeight: "bold", fontSize: 12 }} align='left'>
                                Als vorige versie een interne versie was geef dan hetzelfde versienummer op.
                            </Typography>
                            <br></br>   
                            <TextField 
                                id="outlined-helperText" 
                                label="Versienummer" 
                                type="Number"
                                value={versieNumber} 
                                fullWidth 
                                onChange={handleVersieNumber}      
                            />
                            <br></br>

                            <br></br>
                            <Typography style={{ fontWeight: "bold", fontSize: 20 }} align='left'>
                                Is alle feedback verwerkt?
                            </Typography>
                            {feedback?.map(record => {
                                return (
                                    <div style={{ marginBottom: "5px", justifyContent: "center" }}>
                                        <FormControlLabel control={<Checkbox />} label={record?.get('Name')} id="fp" name={record?.get('Name')} value={record?.get('Name')} onChange={handleFeedback} />
                                    </div>
                                );
                            })}

                            <br></br>
 
                            <Typography align='left'>
                                Geef opmerking voor volgende editor of projectleider:
                            </Typography>

                            <TextField
                                id="outlined-basic" label="" variant="outlined" style={{ overflow: "auto" }}
                                size="small"
                                multiline
                                fullWidth
                                value={opmerking}
                                onChange={(e) => { setOpmerking(e.target.value) }}
                                error={errors.opmerking && "true"}
                                helperText={errors.opmerking && errors.opmerking}

                            />

                            {/* )} */}
                            <br></br>
                            <br></br>



                            <FormControl 
                                fullWidth 
                                
                                error={errors.submitter && "error"}
                                helperText={errors.submitter && errors.submitter}
                                >
                                <InputLabel id="demo-simple-select-error-label">Editor</InputLabel>
                                <Select
                                    labelId="demo-simple-select-error-label"
                                    fullWidth
                                    id="demo-simple-select"
                                    value={submitter}
                                    label="Submitter"
                                    onChange={(e) => setSubmitter(e.target.value)}
                                    
                                >
                                    {submitters.map(record => {
                                        return (
                                            <MenuItem 
                                                value={record?.get('Naam')}>{record?.get('Naam')}
                                                
                                            </MenuItem>
                                        );
                                    })}

                                </Select>
                            </FormControl>
                            
                            <br></br>
                            <br></br>
                            <br></br>
                            <label htmlFor="raised-button-file">
                                <Button variant="outlined" component="span" style={{ backgroundColor: "#486ac9", color: "white", align: "center" }} >
                                    Upload Video
                                </Button>
                            </label>



                            <br></br>
                            <br></br>
                            <input
                                accept="video/*"
                                style={{ display: 'none', backgroundColor: "black", }}
                                align="right"
                                id="raised-button-file"
                                multiple
                                type="file"
                                onChange={handleVideo}

                            />


                            <TextField 
                                disabled id="standard-basic" 
                                label={video?.name} 
                                fullWidth 
                                error={errors.video && "true"}
                                helperText={errors.video && errors.video}
                            
                            />
                            <br></br>                                   
                            <br></br>

                            <br></br>

                                <div>

                                <Typography style={{ fontWeight: "bold", fontSize: 20 }} align='left'>
                                    Thumbnails
                                </Typography>

                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                >
                                    {thumbnail?.map(record => {
                                        return (
                                            <div style={{ marginBottom: "5px", justifyContent: "center" }}>
                                                <FormControlLabel control={<Radio />} id="thumb" name="thumb" value={record} onChange={(e) => {
                                                    
                                                    let buffer;
                                                    axios({
                                                        method: 'get',
                                                        url: "" + e.target.value,
                                                        responseType: 'blob'
                                                    }).then((response) => {
                                                    buffer = response.data;
                                                        setSelectedThumbnail((response.data));

                                                    });
                                                }}
                                                    label={<img src={record} style={{ width: "300px", height: "150px", objectFit: "contain" }}></img>} 
                                                />
                                            </div>

                                        );
                                    })}
                                </RadioGroup>
                            </div>

                            <br></br>
                            <br></br>

                            <Typography style={{ fontWeight: "bold", fontSize: 20 }} align='left'>
                                Mail sturen naar PL?
                            </Typography>

                            <div style={{ marginBottom: "5px", justifyContent: "center" }}>
                                <FormControlLabel control={<Checkbox />} label="Stuur projectleider een mail." id="fp" name="testname" checked={mailPL} onChange={handleMailPL} />
                            </div>



                            {/*  -------------------------------------------------------------*/}
                            {/* {!proccess && ( */}
                            <Typography align='center'>
                                <div style={{ display: "inline" }}>

                                    <Button variant="outlined" align="center" style={{ backgroundColor: "#486ac9", color: "white", align: "center" }} onClick={handleChange}>
                                        Get Link
                                    </Button>
                                </div>
                                <br></br>


                            </Typography>
                            <br></br>
                            <br></br>
                        </Paper>
                    </Form>
                )}
                    {proccess && (
                        <div className="sweet-loading">

                            {perc < 100 && (

                                <ClipLoader color={"black"} loading={true} css={override} size={150} />
                            
                            )}
                            <br />
                            <h1 style={{ marginTop: "15%", marginLeft: "5%", marginLeft: "35%" }}>{perc}% Uploaded
                            </h1>

                            {perc == 100 && (
                                <><a style={{ marginTop: "15%", marginLeft: "5%", marginLeft: "25%" }}
                                href={videoUrl} target="_blank">Vimeo review link
                            </a><br></br>
                            <a style={{ marginTop: "15%", marginLeft: "5%", marginLeft: "25%" }}
                                href={videoUrl2} target="_blank">Vimeo deel link (met downloadbutton)
                            </a><br></br>
                            <a style={{ marginTop: "15%", marginLeft: "5%", marginLeft: "25%" }}
                                href={videoUrlManage} target="_blank">Klik hier om collaboration-settings aan te passen.
                            </a>
                            
                            </>
                            )}
                            {//ERROR UPLOADING VIDEO BESTAAT NIET//
                            }  
                            
                            {perc == 101 && (
                                <h1>{videoUrl}
                                
                                </h1>
                                
                            )}

                        </div>
                    )}


            </div>
        );
    }
}
